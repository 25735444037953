import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaintenanceComponent } from './maintenance.component';
import { NbMenuModule,NbCheckboxModule,NbButtonModule} from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ThemeModule,
    NbMenuModule,
    NbCheckboxModule,
    NbButtonModule
  ],
  declarations: [
    MaintenanceComponent
  ], 
  providers: [
  ]
})
export class MaintenanceModule {}
