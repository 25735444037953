import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.services';

declare var isWaiting: any;

@Component({
  selector: 'maintenance',
  styleUrls: ['./maintenance.component.scss'],
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit{

  constructor(private _authService: AuthService) {

    if(!_authService.data.isMaintenanceMode){

      window.location.href = "/#/auth/login";
    }
  }

  ngOnInit() {
    isWaiting(false);

  }

}