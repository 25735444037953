import { Component } from '@angular/core';

@Component({
  selector: 'ngx-auth',
  styleUrls: ['auth.component.scss'],
  template: `
  
  <nb-layout win windowMode>
    <nb-layout-column>
      <nb-card>
        <nb-card-body>
          <router-outlet></router-outlet>
        </nb-card-body>
      </nb-card>
    </nb-layout-column>
  </nb-layout>
  <div id="div-waiting" class="overlay">
    <div class="waiting-overlay">
      </div>
      <div class="waiting-message d-flex align-items-center justify-content-center">
          <img class="loading-img" src="assets/images/loading-spinner-blue.gif">
          <span class="loading-text"> Loading...</span>
      </div>
  </div>
  `,
})
export class AuthComponent {}
