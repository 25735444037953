import { Component, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.services';

declare var baseconfig: any;
declare var jSW: any;
declare var session: any;
declare var sessionStatus: any;
declare var sessionCallback: any;
declare var notificationCallback: any;
declare var getDataLoop: any;
declare var isWaiting: any;
declare var baseLatitude: any;
declare var baseLongitude: any;

@Component({
  selector: 'login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class Login {
  public username: AbstractControl;
  public password: AbstractControl;
  public submitted: boolean = false;

  @ViewChild('form', { static: false }) loginForm: HTMLFormElement;

  constructor(fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _http: HttpClient,
    private _authService: AuthService) {

    baseconfig = _authService.data;

    if(baseconfig.isMaintenanceMode){

      window.location.href = "/#/auth/maintenance-mode";
    }
    
    jSW.swInit({
      url: baseconfig.api.protocal + "://" + baseconfig.api.ip + ":" + baseconfig.api.webport,
      calltype: jSW.CallProtoType.HTTP,
    });

    session = null;
    isWaiting(false);
  }

  public onSubmit(form: any, isValid: boolean): void {

    this.submitted = true;
    
    getDataLoop = 0;

    if (!isValid) {
      this.submitted = false;
      return;
    }

    isWaiting(true);
    
    session = new jSW.SWSession({
      server: baseconfig.api.ip,
      port: baseconfig.api.port,
      onopen: function (sess) {
        sess.swLogin({
          user: form.userName,
          password: form.password
        });
      }
    });

    session.swAddCallBack('login', sessionCallback);

    sessionStatus = null;

    this.checkLoginStatus();
  }


  public checkLoginStatus(){

    var that = this;

      if(sessionStatus == null){

        setTimeout(function () {
          
          if(getDataLoop < 5 ){

            getDataLoop++;
    
            that.checkLoginStatus();
          }else{
    
            that.toastr.error('Authentication failed! Invalid username or password.');
    
            setTimeout(function () {
      
              window.location.reload();
            }, 500);
          }
        },1000);
      }else if (!sessionStatus) {

        that.submitted = false;

        that.toastr.error('Authentication failed! Invalid username or password.');

        isWaiting(false);
      } else {

      getDataLoop = 0;
      session.swGetConfManager().swInit({}); // null callback;
      session.swAddCallBack('logout', sessionCallback);
      session.swAddCallBack('notify', notificationCallback);
      that.checkUserInfo();
      }
    
  }

  public checkUserInfo() {
    var that = this;
    if(session._userManager == null ||
      session._userManager._currentUser == null || 
      session._userManager._currentUser._userinfo == null ||
      session._userManager._currentUser._userinfo.sysadmin == null){
        
        setTimeout(function () {
          if(getDataLoop < 5 ){

            getDataLoop++;
    
            that.checkUserInfo();
          }else{
    
            that.toastr.error('Authentication failed! Invalid username or password.');
    
            setTimeout(function () {
      
              window.location.reload();
            }, 500);
          }
        }, 500);
    }
    else if (session._userManager._currentUser._userinfo.sysadmin.isGroup &&
      session._userManager._currentUser._userinfo.sysadmin.isUser &&
      session._userManager._currentUser._userinfo.sysadmin.isDev &&
      session._userManager._currentUser._userinfo.sysadmin.isDevAss) {
      
      baseLatitude = baseconfig.defaultlocation.latitude;
      baseLongitude = baseconfig.defaultlocation.longitude;

      if(session._userManager._currentUser._userinfo.description != null && session._userManager._currentUser._userinfo.description != ""){
        
        var desc = JSON.parse(session._userManager._currentUser._userinfo.description);

        if(desc && desc.DefaultLocation){

          baseLatitude = desc.DefaultLocation.latitude;
          baseLongitude = desc.DefaultLocation.longitude;
        }
      }

      if(session._arr_pu_online.length > 0)
      {
        for(var i=0;i<session._arr_pu_online.length;i++){

          session._userManager.swGetUAInfoByPuid({
              targetId:session._arr_pu_online[i]._id_pu,
              tag:null,
              callback: function (options, res,result) 
                  {
                      var index = session._arr_pu_online.map(function(e) { return e._id_pu; }).indexOf(result[0].szdevid);

                      session._arr_pu_online[index]._userid = result[0].szuserid;
                      session._arr_pu_online[index]._username = result[0].szusername;

                      if(index==session._arr_pu_online.length-1){
                          
                        window.location.href = "/#/pages/dashboard";
                      }
                  }
              });
        }
      }
      else window.location.href = "/#/pages/dashboard";

    } else {

      session.swLogout();

      that.submitted = false;

      that.toastr.error('You dont have permission to access this system!');

      setTimeout(function () {

        window.location.reload();
      }, 500);
    }
  }

}



