import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="ngx-foot-row row">
      <span class="col-12 col-md-6 created-by">Copyright© <b>WOLFCOM</b>® 2020</span>
      <div class="col-12 col-md-6 socials">
        <a href="#" target="_blank" class="ion ion-social-facebook"></a>
        <a href="#" target="_blank" class="ion ion-social-twitter"></a>
        <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
      </div>
    </div>
  `,
})
export class FooterComponent {
}
