import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';
import { EnvironmentSpecificService } from "../environment-specific.service";

@Injectable()
export class AuthService {

    // apiUrl:any;
    // developmentEnv:boolean = true;
    data:any;

    constructor(private _http: HttpClient,private _envService: EnvironmentSpecificService) {
        this.data = this._envService.envSpecific;
        // this.apiUrl = this._envService.envSpecific.apiUrl;
        // this.developmentEnv = this._envService.envSpecific.developmentEnv;
    }
}
