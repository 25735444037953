import { NgModule } from '@angular/core';
import { NbMenuModule,
  NbLayoutModule,
  NbCardModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { LoginModule } from './login/login.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { AuthComponent } from './auth.component';
import {} from './maintenance/maintenance.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth.services';

@NgModule({
  imports: [
    ThemeModule,
    NbMenuModule,
    NbLayoutModule,
    LoginModule,
    RouterModule,
    NbCardModule,
    MaintenanceModule
  ],
  declarations: [
    AuthComponent,
  ],
  providers: [
    AuthService
  ]

})
export class AuthModule {
}