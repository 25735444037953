import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomActionViewComponent } from  './smart-table-custom-button-view/custom-action-view.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule 
    ],    
    declarations: [
        CustomActionViewComponent,
    ],
    exports: [
        CustomActionViewComponent,
    ],
    providers: [
    ],
    entryComponents: [ ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: SharedModule,
            providers: [],
        };
    }
}