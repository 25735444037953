import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

import { EnvSpecific } from './environment-specific-resolver.service';

@Injectable()
export class EnvironmentSpecificService {

  public envSpecific: EnvSpecific = null;
  public envSpecificNull: EnvSpecific = null;
  private envSpecificSubject: BehaviorSubject<EnvSpecific> = new BehaviorSubject<EnvSpecific>(null);

  constructor(private http: HttpClient) {
    this.loadEnvironment().then(es => {
        this.setEnvSpecific(es);
    }, error => {
        console.log(error);
        return null;
    })
  }

  public loadEnvironment()  {

      if (this.envSpecific === null || this.envSpecific === undefined) {

        return this.http.get('assets/env-specific.json')
        .map((data) => {
          return data;
        }).toPromise<any>();
      }else{
        return Promise.resolve(this.envSpecificNull);
      }
  }

  
  public loadClientIP()  {

    if (this.envSpecific === null || this.envSpecific === undefined) {

      return this.http.get('http://api.ipify.org/?format=json')
      .map((data) => {
        return data;
      }).toPromise<any>();
    }else{
      return Promise.resolve(this.envSpecificNull);
    }
}

  public setEnvSpecific(es: EnvSpecific) {

    if (es === null || es === undefined) {
        return;
    }

    this.envSpecific = es;

    if (this.envSpecificSubject) {
        this.envSpecificSubject.next(this.envSpecific);
    }
  }

  public subscribe(caller: any, callback: (caller: any, es: EnvSpecific) => void) {
      this.envSpecificSubject
          .subscribe((es) => {
              if (es === null) {
                  return;
              }
              callback(caller, es);
          });
  }
}


//https://www.intertech.com/deploying-angular-4-apps-with-environment-specific-info/