import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Login } from './login.component';
import { NbMenuModule,NbCheckboxModule,NbButtonModule} from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { AuthService } from '../auth.services';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ThemeModule,
    NbMenuModule,
    NbCheckboxModule,
    NbButtonModule
  ],
  declarations: [
    Login
  ], 
  providers: [
    AuthService
  ]
})
export class LoginModule {}
